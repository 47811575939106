// All Css Here
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "../public/assets/css/plugins.css";
@import "../public/assets/scss/style";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "node_modules/react-modal-video/scss/modal-video.scss";

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.content-input input,
.content-select select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.lenguage-select {
  font-family: "Poppins", sans-serif;
  border-color: black;
  color: white;
}

.lenguage-select:hover {
  border-color: #0ef9a0;
}

.lenguage-select option {
  font-family: "Poppins", sans-serif;
  color: black;
}

.lenguage-select-mobile {
  font-family: "Poppins", sans-serif;
  border-color: white;
}

.lenguage-select-mobile option {
  font-family: "Poppins", sans-serif;
}

.inputText {
  font-family: "Poppins", sans-serif;
}

.about_me_contianer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 2rem 0;
  justify-items: center;
  align-items: center;
}

.about_me_title {
  grid-column: 1/3;
}

.about_me_text {
  display: flex;
  flex-direction: column;
  color: #a3a3a3;
  padding: 3rem 2rem;
}

.about_me_text p {
  font-size: 14px;
  text-align: start;
}

.about_me_img {
  display: flex;
  align-content: center;
  justify-content: center;
}

@media only screen and (min-width: 768px) {
  .about_me_img {
    padding: 2rem 0rem;
  }
}

.button_contact {
  padding: 1.5rem;
  color: #0ef9a0 !important;
  background-color: #191919;
  border-radius: 99px;
  border: 1px solid black;
  font-size: 16px;
}

.button_contact:hover {
  color: black !important;
  background-color: #0ef9a0;
}

.photo_about_me {
  object-fit: contain;
  width: 65%;
  border-radius: 3rem;
}

.photo_contact {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem 4rem;
}

@media only screen and (max-width: 768px) {
  .about_me_contianer {
    display: flex;
    flex-direction: column-reverse;
  }

  .photo_about_me {
    width: 100%;
    margin: 2rem 0;
  }

  .about_me_text {
    padding: 1rem 0;
  }

  .title_mobile {
    margin: 11vh 0;
  }

  .lenguage-select-mobile {
    border: 1px solid #0ef9a0;
    color: white;
  }

  .photo_contact {
    position: absolute;
    top: 80%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    padding: 2rem 4rem;
    display: flex;
    justify-content: center;
  }

  .button_contact {
    padding: 0.75rem;
    font-size: 12px;
  }

  .about_me_title {
    align-self: start;
  }
}
